import * as React from "react";
import { Link } from "gatsby";
import Loadable from "react-loadable";

import Layout from "../layouts/default";
import Breadcrum from "../components/search/components/Breadcrum";

const LoadableSearchResults = Loadable({
  loader: () => import("../components/search/SearchResults"),
  loading: () => null
});

export default function SearchResultsPage() {
  return (
    <Layout noSearch>
      {() => (
        <div>
          <Breadcrum>
            <Link to="/">Documentation</Link>
            <span style={{ margin: "0 5px" }}>/</span>
            <span>Search results</span>
          </Breadcrum>
          <h1>Documentation Search</h1>
          <LoadableSearchResults />
        </div>
      )}
    </Layout>
  );
}
