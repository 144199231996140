import React from "react";
import styles from "./style.module.scss";

const Breadcrum: React.FC<any> = ({ children }) => {
  return (
    <div className={styles.container}>
      <h6>{children}</h6>
      <hr />
    </div>
  );
};

export default Breadcrum;
